import request from "@/api/request";
import picUrl from "@/api/picture";
// const baseURL = "http://cpsapi.yy12365.com";
// 查询用户列表
function queryUser(
  pageNumber,
  pageSize,
  userLevel,
  userName,
  custName,
  userType,
  data
) {
  return request({
    method: "get",
    url: `/user/allCustomerUser?pageNumber=${pageNumber}&pageSize=${pageSize}&userLevel=${userLevel}&USERNAME=${userName}&custName=${custName}&userType=${userType}`,
    data,
  });
}
// 获取首页banner图
function getbanners(pageNumber, pageSize, data) {
  return request({
    method: "get",
    url: `/mallbanner?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data,
  });
}
// 新增banner
function addbanners(data) {
  return request({
    method: "post",
    url: `/mallbanner`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 修改banner
function editbanners(id, data) {
  return request({
    method: "put",
    url: `/mallbanner/${id}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 删除banner
function delbanners(id, data) {
  return request({
    method: "delete",
    url: `/mallbanner/${id}`,
    data,
  });
}
// 获取文章列表（首页公告）
function getArticle(pageNumber, pageSize, data) {
  return request({
    method: "get",
    url: `/mallarticlemanage?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    data,
  });
}
// 新增文章
function addNotice(data) {
  return request({
    method: "post",
    url: `/mallarticlemanage`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 修改文章
function editNotice(id, data) {
  return request({
    method: "put",
    url: `/mallarticlemanage/${id}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 删除文章
function delNotice(id, data) {
  return request({
    method: "delete",
    url: `/mallarticlemanage/${id}`,
    data,
  });
}
// 修改店铺信息
function editShop(id, data) {
  return request({
    method: "put",
    url: `/malluserfather/${id}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 修改公司简介信息
function modificationgsjj(id, data) {
  return request({
    method: "put",
    url: `/companyprofile/${id}`,
    data,
  });
} // 修改联系我们信息
function modificationlxwm(id, data) {
  return request({
    method: "put",
    url: `/mallaboutus/${id}`,
    data,
  });
}
// 修改转账信息
function modificationzzxx(id, data) {
  return request({
    method: "put",
    url: `/bankaccounttransfer/${id}`,
    data,
  });
}
// 修改预约自提信息
function modificationyyzt(id, data) {
  return request({
    method: "put",
    url: `/appointmentpickup/${id}`,
    data,
  });
}
// 修改送货上门信息
function modificationshsm(id, data) {
  return request({
    method: "put",
    url: `/providehomedelivery/${id}`,
    data,
  });
}
// 获取店铺信息
function getShop(id, data) {
  return request({
    method: "get",
    url: `/malluserfather/${id}`,
    data,
  });
}
// 获取所有客服
function getAllCustomer(data) {
  return request({
    method: "get",
    url: `/user/getAllCustomer`,
    data,
  });
}
// 获取订单列表
function queryOrder(
  startTime,
  endTime,
  condition,
  pageSize,
  pageNumber,
  status,
  paytype,
  whetherFullPayment,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/orderInfoList?startTime=${startTime}&endTime=${endTime}&condition=${condition}&pageSize=${pageSize}&pageNumber=${pageNumber}&status=${status}&paytype=${paytype}&whetherFullPayment=${whetherFullPayment}`,
    data,
  });
}
// 获取用户等级
function getUserLeve(data) {
  return request({
    method: "get",
    url: `/user/getAllUserLevel`,
    data,
  });
}
// 订单审核
function orderExamine(id, data) {
  return request({
    method: "put",
    url: `/orderinfo/${id}`,
    data,
  });
}
// 修改用户等级和授信额度
function editMember(id, finance, type, levelId, phone, day, data) {
  return request({
    method: "put",
    url: `/user/editFinance?userId=${id}&finance=${finance}&type=${type}&levelId=${levelId}&phone=${phone}&day=${day}`,
    data,
  });
}
// 订单发货
function orderSend(id, data) {
  return request({
    method: "patch",
    url: `/orderinfo/${id}`,
    data,
  });
}
// 修改订单金额
function orderEditPrice(id, price, data) {
  return request({
    method: "put",
    url: `/orderinfo/modifyPrice?orderId=${id}&price=${price}`,
    data,
  });
}
// 申请发票列表

function orderInfos(pageSize, pageNumber, issueStruts, data) {
  return request({
    method: "get",
    url: `/issueaninvoice?pageSize=${pageSize}&pageNumber=${pageNumber}&issueStruts=${issueStruts}`,
    data,
  });
}
// 发票详情

function invoiceDetails(id, data) {
  return request({
    method: "get",
    url: `/increaseticketqualification/${id}`,
    data,
  });
}
// 同意开票
function sureKai(id, data) {
  return request({
    method: "put",
    url: `/issueaninvoice/${id}`,
    data,
  });
}
// 开票详情
function invoiceInfo(id, data) {
  return request({
    method: "get",
    url: `/issueaninvoice/${id}`,
    data,
  });
}
// 投诉详情
function complaintInfo(id, data) {
  return request({
    method: "get",
    url: `/ordercomplaints/${id}`,
    data,
  });
}
// 投诉修改
function complaintEdit(id, data) {
  return request({
    method: "put",
    url: `/ordercomplaints/${id}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 报表

function report(
  startTime,
  endTime,
  struts,
  payStruts,
  pageSize,
  pageNumber,
  whetherFullPayment,
  customerId,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/getOrderInfoReport?startTime=${startTime}&endTime=${endTime}&struts=${struts}&payStruts=${payStruts}&pageSize=${pageSize}&pageNumber=${pageNumber}&whetherFullPayment=${whetherFullPayment}&customerId=${customerId}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
// 补款
function editFull(id, data) {
  return request({
    method: "put",
    url: `/orderpayinfo/editFull/${id}`,
    data,
  });
}
// 获取对账列表
function orderReconciliation(
  id,
  pageSize,
  pageNumber,
  type,
  cid,
  userId,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/orderReconciliation?userId=${id}&pageSize=${pageSize}&pageNumber=${pageNumber}&orderReconciliation=${type}&CCId=${cid}&CCUID=${userId}`,
    data,
  });
}
// 开始对账
function startReconciliation(data) {
  return request({
    method: "post",
    url: `/orderinfo/startReconciliation`,
    data,
  });
}
// 结束对账
function endReconciliation(data) {
  return request({
    method: "post",
    url: `/orderinfo/endReconciliation`,
    data,
  });
}
// 获取客服
function getAllUserDontExistCustom(data) {
  return request({
    method: "get",
    url: `/user/getAllUserDontExistCustom?userType=1`,
    data,
  });
}
// 获取客户
function getAllUserDontExistCustom1(
  pageSize,
  pageNumber,
  userName,
  name,
  data
) {
  return request({
    method: "get",
    url: `/user/getAllUserDontExistCustom?pageSize=${pageSize}&pageNumber=${pageNumber}&userName=${userName}&name=${name}`,
    data,
  });
}
// function getAllUserDontExistCustom1(data) {
//   return request({
//     method: "get",
//     url: `/user/getAllUserDontExistCustom`,
//     data,
//   });
// }
// 对账历史记录
function mallofflineorderreconciliation(
  id,
  pageNumber,
  pageSize,
  startTime,
  endTime,
  data
) {
  return request({
    method: "get",
    url: `/mallofflineorderreconciliation?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&startTime=${startTime}&endTime=${endTime}`,
    data,
  });
}
//对账记录ERP
function orderReconciliationERP(
  id,
  customerId,
  pageSize,
  pageNumber,
  startTime,
  endTime,
  type,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/orderReconciliationERP?userId=${id}&customerId=${customerId}&pageSize=${pageSize}&pageNumber=${pageNumber}&startTime=${startTime}&endTime=${endTime}&accStatus=${type}`,
    data,
  });
}
//对账记录ERP导出
function orderReconciliationERPExport(
  id,
  customerId,
  pageSize,
  pageNumber,
  startTime,
  endTime,
  type,
  data
) {
  return request({
    method: "get",
    url: `/orderinfo/orderReconciliationERPExport?userId=${id}&customerId=${customerId}&pageSize=${pageSize}&pageNumber=${pageNumber}&startTime=${startTime}&endTime=${endTime}&accStatus=${type}`,
    data,
  });
}
// 新增客户
function addCustomers(data) {
  return request({
    method: "post",
    url: `/login/addClient`,
    data,
  });
}
// 订单详情
function getProductInfoByOrderId(id, data) {
  return request({
    method: "get",
    url: `/orderinfo/getProductInfoByOrderId?orderId=${id}`,
    data,
  });
}
// 客服开票列表
function requestaninvoiceList(
  pageSize,
  pageNumber,
  startTime,
  endTime,
  userId,
  invoicingStuts,
  data
) {
  return request({
    method: "get",
    url: `/requestaninvoice/getRequestanInvoiceDataList?pageSize=${pageSize}&pageNumber=${pageNumber}&startTime=${startTime}&endTime=${endTime}&invoicingApplicant=${userId}&invoicingStuts=${invoicingStuts}`,
    data,
  });
}
// 客服申请开票（新增）
function requestaninvoice(data) {
  return request({
    method: "post",
    url: `/requestaninvoice`,
    data,
  });
}
// 发票号填写
function requestaninvoicePut(id, data) {
  return request({
    method: "put",
    url: `/requestaninvoice/${id}`,
    data,
  });
}
// 当前客服的客户列表(发票处理)
function getTotalAmountSigned(pageSize, pageNumber, name, data) {
  return request({
    method: "get",
    url: `/requestaninvoice/getTotalAmountSigned?pageSize=${pageSize}&pageNumber=${pageNumber}&name=${name}`,
    data,
  });
}
// 管理员查看客服下的客户
function getCustomerClient(pageSize, pageNumber, name, userId, data) {
  return request({
    method: "get",
    url: `/user/getCustomerClient?pageSize=${pageSize}&pageNumber=${pageNumber}&name=${name}&userId=${userId}`,
    data,
  });
}
// 修改客户所属客服
function editSalesmanid(id, data) {
  return request({
    method: "put",
    url: `/user/editSalesmanid/${id}`,
    data,
  });
}
// 会员变更列表
function membershipgradechangeapplicat(pageSize, pageNumber, data) {
  return request({
    method: "get",
    url: `/membershipgradechangeapplicat?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    data,
  });
}
// 会员变更审核
function changeMembers(id, data) {
  return request({
    method: "put",
    url: `/membershipgradechangeapplicat/${id}`,
    data,
  });
}
// 补款操作
function transferReplenishment(data) {
  return request({
    method: "post",
    url: `/usewallet/transferReplenishment`,
    data,
  });
}

function queryFeeDetail(userfatherid,orderId, data) {
  return request({
    method: "get",
    url: `/cpshttp/getExtendInfoListById?userfatherid=${userfatherid}&orderId=${orderId}`,
    data,
  });
}

function queryEnterprise(data) {
  return request({
    method: "get",
    url: `/malluserfather/getEnterprise`,
    data,
  });
}

function sfOrder(data) {
  return request({
    method: "post",
    url: picUrl.sfUrl + `/sfService/addOrder`,
    data,
  });
}

function sfPDFFile(data) {
  return request({
    method: "post",
    url: picUrl.sfUrl + `/sfService/printOrder`,
    data,
  });
}

function querySfOrder(data) {
  return request({
    method: "post",
    url: picUrl.sfUrl + `/sfService/queryOrder`,
    data,
  });
}

function getInvoiceConfig(data) {
  return request({
    method: "get",
    url: `/requestaninvoice/obtainTaxRateConfig`,
    data,
  });
}


function requestaninvoiceMyList(
    pageSize,
    pageNumber,
    startTime,
    endTime,
    invoicingStuts,
    data
) {
  return request({
    method: "get",
    url: `/requestaninvoice/getRequestanInvoiceHistoryDataList?pageSize=${pageSize}&pageNumber=${pageNumber}&startTime=${startTime}&endTime=${endTime}&invoicingStuts=${invoicingStuts}`,
    data,
  });
}
export default {
  transferReplenishment,
  changeMembers,
  membershipgradechangeapplicat,
  editSalesmanid,
  getAllCustomer,
  getCustomerClient,
  requestaninvoicePut,
  requestaninvoiceList,
  requestaninvoiceMyList,
  requestaninvoice,
  getTotalAmountSigned,
  orderReconciliationERPExport,
  orderReconciliationERP,
  getProductInfoByOrderId,
  addCustomers,
  getAllUserDontExistCustom1,
  getAllUserDontExistCustom,
  mallofflineorderreconciliation,
  endReconciliation,
  startReconciliation,
  orderReconciliation,
  editFull,
  report,
  complaintEdit,
  complaintInfo,
  invoiceInfo,
  sureKai,
  orderInfos,
  getbanners,
  getArticle,
  addbanners,
  editbanners,
  delbanners,
  addNotice,
  editNotice,
  delNotice,
  queryUser,
  editShop,
  getShop,
  queryOrder,
  getUserLeve,
  orderExamine,
  modificationgsjj,
  modificationlxwm,
  modificationzzxx,
  modificationyyzt,
  modificationshsm,
  editMember,
  orderSend,
  orderEditPrice,
  invoiceDetails,
  queryFeeDetail,
  queryEnterprise,
  sfOrder,
  sfPDFFile,
  querySfOrder,
  getInvoiceConfig
};
